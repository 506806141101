// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#why-replacing-an-existing-rest-api-with-graphql"
        }}>{`Why Replacing an Existing REST API with GraphQL?`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#how-can-you-do-it"
        }}>{`How Can You Do It?`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`In this post, I will explain you, how you can start using GraphQL from the frontend even
if it is still not implemented on the backend side.`}</p>
    <p>{`Some of you reading this article might still wait for the implementation of GraphQL by the backend
team which is supposed to arrive as soon. Or you just want try it out to make your own decision about
if you need it or not.`}</p>
    <p>{`Or perhaps you just dropped the idea of having it as a part of your stack because you think
it should be first implemented on the backend side otherwise impossible.`}</p>
    <p>{`If you belong to one of these examples, I have a good news for you because you will learn in this post how you
can do it from the frontend without begging your backend teammates.`}</p>
    <p>{`There is even a chance they will push with you this idea because you will do this completely alone. That will let them
all the time needed to tame the beast hidden in the backend!`}</p>
    <h2 id="why-replacing-an-existing-rest-api-with-graphql">
  Why Replacing an Existing REST API with GraphQL?
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You might be working with some rest APIs who are really old and considered as part of your company legacy.
The amount of data received from these endpoints is really huge, when at the end you are just requesting
these endpoints to gather only few properties. Impacting your performance, workflow etc...`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You don't have access to the backend codebase. Not because someone is not allowing you to do so but because
there is a high risk to die in horrible circumstances after touching it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You already know all the benefits of GraphQL and you just want to use it now!`}</p>
      </li>
    </ul>
    <h2 id="how-can-you-do-it">
  How Can You Do It?
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Few options are available today and like always, it depends on how big is your project and how much time
you have in front of you`}</p>
    <p>{`If you are really interested by this topic but you have less than 5 minutes to scratch the surface in order
to dig a bit more deeper later... I've got you ^^`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/z9ygk"
      }}>{`Check the code in codesandbox`}</a></p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/rick_morty_codesandbox.png')} loading="lazy" alt="rick_morty_codesandbox" description="Code example on codesandbox" />
    <h3>{`REST API wrapped with GraphQL`}</h3>
    <p>{`Typical example of how you can improve what is received from your restful api endpoint:`}</p>
    <p>{`From a given existing rest api like the one used above, let say we need to retrieve all the characters
names only from this endpoint. (Which is throwing us all the characters lives as well)`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/rick_morty_api_response.png')} loading="lazy" alt="rick_morty_codesandbox" description="Rick and Morty all characters response example" />
    <p>{`Technically speaking if you are doing a regular rest api request because there is no other endpoint
to do so, you just can't.`}</p>
    <p>{`If you are wrapping this API with a GraphQL client, you can tweak, retrieve anything you need from this
endpoint. Don't even have to worry about how your query should be formatted...`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/rick_morty_response_character_only.png')} loading="lazy" alt="rick_morty_codesandbox" description="Rick and Morty response with only characters names" />
    <p>{`Few link to easily wrap any rest api in the most straightforward way:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/link/links/rest/"
        }}>{`Apollo link rest`}</a>{` (used in the example above)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.apollographql.com/docs/apollo-server/"
        }}>{`Apollo server`}</a>{` - With an express server`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.amplify.aws/"
        }}>{`AWS Amplify`}</a>{` - (Post and tutorial coming soon)`}</li>
    </ul>
    <p>{`and probably others that you will be able to find `}<a parentName="p" {...{
        "href": "https://graphql.org/code/#javascript"
      }}>{`here`}</a>{` in the official GraphQL documentation .`}</p>
    <p><em parentName="p">{`The ones I listed for you, are just the ones I personally tried.`}</em></p>
    <p><inlineCode parentName="p">{`Happy coding!`}</inlineCode></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   